import React, { useContext, useState, useEffect, useCallback } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Button,
  Modal,
  Breadcrumb,
  Tabs,
  TextInput,
  Select,
  Tooltip,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdAddCircleOutline, IoMdArrowBack } from "react-icons/io";
import { useNavigate, Link, useParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle, HiPencil } from "react-icons/hi";
import img from "../assets/images/user.png";
import { capitalizeFirstLetters } from "../utils/strings";
import { formatDate } from "../utils/date";

function SingleCustomer() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [msg, setMsg] = useState([]);
  let [subs, setSubs] = useState([]);
  let [loading, setLoading] = useState(false);
  let [client, setClient] = useState({});
  const [openModalOut, setOpenModalOut] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);
  let [imageOrId, setImageOrId] = useState("");
  let [error, setError] = useState("");
  let [errorColor, setErrorColor] = useState("");
  let [loading3, setLoading3] = useState(false);
  let [properties, setProperties] = useState([]);
  let [files, setFiles] = useState([]);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateType, setUpdateType] = useState(""); // Either 'progress' or 'status'
  const [updateValue, setUpdateValue] = useState(""); // Value entered in the input
  const [currentSubId, setCurrentSubId] = useState(null); // ID of the subscription being updated
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { id } = useParams();
  const [subscription, setSubscription] = useState({
    propertyId: "",
    clientId: id,
    paymentOption: "",
    pmb: "",
  });
  const navigate = useNavigate();

  const handleAuthError = (error) => {
    const unauthorizedErrors = [
      "Access Denied: Invalid or expired token. Please login again",
      "Access Denied: Please log in.",
      "Access Denied: Invalid Token.",
      "Access Denied: Invalid or expired token.",
      "Access Denied: Invalid or Expired Token.",
      "Access Denied: No token provided.",
    ];
    if (unauthorizedErrors.includes(error.message)) {
      setMsg("Session Expired!");
      setOpenModalOut(true);
      localStorage.removeItem("royal-token");
    } else {
      console.log(error);
    }
  };

  const loadClient = useCallback(async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/client/${id}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setClient(data);
      setLoading(false);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  }, [id, baseUrl]);

  const loadClientSubs = useCallback(async () => {
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/client/admin/subscription/${id}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setSubs(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  }, [id, baseUrl]);

  const loadProperties = useCallback(
    async (a) => {
      setLoading(true);
      try {
        let token = localStorage.getItem("royal-token");
        let url = `${baseUrl}/list-properties`;
        const { data } = await apiRequest(url, "GET", null, token);
        setProperties(data);
      } catch (error) {
        handleAuthError(error);
      } finally {
        setLoading(false);
      }
    },
    [baseUrl]
  );

  useEffect(() => {
    Promise.all([loadClient(), loadClientSubs(), loadProperties()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [loadClient, loadClientSubs, loadProperties]);

  const respondToSub = async (action, id) => {
    if (action === "update") {
      setCurrentSubId(id);
      setOpenUpdateModal(true);
      return; // Exit here to wait for modal submission
    }

    const body = {
      approved: action === "approve",
      status: action === "approve" ? "in progress" : "cancelled",
    };

    const confirmed = window.confirm(`Are you sure you want to ${action}?`);
    if (!confirmed) return;

    handleApiRequest(body, id);
  };

  const handleApiRequest = async (body, id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/approve/subscription/${id}`;
      const { data } = await apiRequest(url, "PUT", body, token);

      setSubs((prevSubs) =>
        prevSubs.map((sub) => (sub.id === data.id ? data : sub))
      );
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteSub = async (a) => {
    const confirmed = window.confirm(`Are you sure you want to delete`);
    if (confirmed) {
      setLoading(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/subscription/${a}`;
        await apiRequest(url, "DELETE", null, token);
        loadClientSubs();
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          console.log(error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscription((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files) {
      const selectedImages = Array.from(files).slice(0, 1); // Limit to 4 files
      setFiles(selectedImages); // Update the state with selected files
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setError("");
    setSubscription({
      propertyId: "",
      clientId: id,
      paymentOption: "",
      pmb: "",
    });
  };

  const handleCloseModalImage = () => {
    setOpenModalImage(false);
    setError("");
    setFiles([]);
    setImageOrId("");
    setLoading3(false);
  };

  let logOut = () => {
    navigate("/");
  };

  const subscribeToProperty = async (a, b) => {
    if (
      !subscription.pmb ||
      !subscription.clientId ||
      !subscription.paymentOption ||
      !subscription.propertyId
    ) {
      setErrorColor("red");
      setError("Please fill all missing fields.");
      return;
    }
    const confirmed = window.confirm(
      "Are you sure you want to subscribe client to the selected property?"
    );
    if (confirmed) {
      setLoading3(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/subscription`;
        await apiRequest(url, "POST", subscription, token);
        setErrorColor("green");
        setError("Successfully subscribed.");
        setLoading3(false);
        setSubscription({
          propertyId: "",
          clientId: id,
          paymentOption: "",
          pmb: "",
        });
        setError("");
        handleCloseModal();
        loadClientSubs();
        setLoading(false);
      } catch (error) {
        const unauthorizedErrors = [
          "Access Denied: Invalid or expired token. Please login again",
          "Access Denied: Please log in.",
          "Access Denied: Invalid Token.",
          "Access Denied: Invalid or expired token.",
          "Access Denied: Invalid or Expired Token.",
          "Access Denied: No token provided.",
        ];
        if (unauthorizedErrors.includes(error.message)) {
          setMsg("Session Expired!");
          setOpenModalOut(true);
          localStorage.removeItem("royal-token");
        } else {
          setErrorColor("red");
          setError(error.message);
          setLoading3(false);
          console.log(error);
        }
      }
    }
  };

  const editImage = async () => {
    if (!files) {
      setErrorColor("red");
      setError(
        `Please select a ${imageOrId === "image" ? "image" : "file"} to upload`
      );
      return;
    }

    const confirmationMessage =
      imageOrId === "image"
        ? "Are you sure you want to change the client's image?"
        : "Are you sure you want to change the client's identification?";
    const confirmed = window.confirm(confirmationMessage);

    if (!confirmed) return;

    setLoading3(true);

    try {
      const token = localStorage.getItem("royal-token");
      const url =
        imageOrId === "image"
          ? `${baseUrl}/image/client/${id}`
          : `${baseUrl}/identity/client/${id}`;

      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));

      await apiRequest(url, "PUT", formData, token);

      setErrorColor("green");
      setError(
        `Successfully changed ${
          imageOrId === "image" ? "image" : "identification"
        }.`
      );

      setLoading3(false);
      loadClient();
      handleCloseModalImage();
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];

      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        setErrorColor("red");
        setError(error.message);
        console.error(error);
      }
      setLoading3(false);
    }
  };

  return (
    <>
      <title>Clients</title>
      <meta
        name="description"
        content="Customers Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
        <div className="container-fluid mx-auto">
          <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
            <SideBar customers="actives" />
            <div className="col-span-5 md:col-span-4" data-aos="zoom-in">
              <div className="mb-4">
                <Breadcrumb>
                  <Link to="/clients">
                    <Breadcrumb.Item>
                      <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                        Clients
                      </h5>
                    </Breadcrumb.Item>
                  </Link>
                  <Breadcrumb.Item>
                    {client?.firstName} {client?.lastName}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="flex flex-wrap gap-2 mt-5 justify-end">
                  <Button color="success" onClick={() => setOpenModal(true)}>
                    <IoMdAddCircleOutline className="me-2" />
                    New Subscription
                  </Button>
                  <Button as={Link} to="/clients" className="bg-#6eb5ff">
                    <IoMdArrowBack className="me-2" />
                    Go Back
                  </Button>
                </div>
              </div>
              {!loading ? (
                <>
                  <div className="">
                    <Tabs.Group
                      aria-label="Tabs with underline"
                      id="tab"
                      style="fullWidth"
                    >
                      <Tabs.Item active title="Profile">
                        <Card>
                          <div className="flex justify-around align-middle items-center pb-1 flex-wrap gap-5">
                            <div style={{ position: "relative" }}>
                              <img
                                src={client.image || img}
                                alt={`${client.firstName} ${client.lastName}`}
                                className={
                                  client.image
                                    ? "object-cover w-full md:w-1/2 lg:w-1/3 rounded-xl"
                                    : ""
                                }
                                style={
                                  !client.image ? { borderRadius: "50%" } : {}
                                }
                              />
                              <Tooltip content="Change Client Image">
                                <HiPencil
                                  size={16}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOpenModalImage(true);
                                    setImageOrId("image");
                                  }}
                                />
                              </Tooltip>
                            </div>
                            <div className="flex flex-col">
                              <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                                {client.firstName} {client.lastName}
                              </h5>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Phone:</strong> {client.phone}
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Occupation:</strong> {client.occupation}
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Email:</strong> {client.email}
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Date Of Birth:</strong>{" "}
                                {formatDate(client.dob)}
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Gender:</strong>{" "}
                                {capitalizeFirstLetters(client.gender)}
                              </span>
                              <span className="text-sm text-gray-500 dark:text-gray-400">
                                <strong>Marital Status:</strong>{" "}
                                {capitalizeFirstLetters(client.maritalStatus)}
                              </span>
                            </div>
                          </div>

                          <div>
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                              <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex gap-1 flex-1">
                                    <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                      Address:
                                    </p>
                                    <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                      {capitalizeFirstLetters(client?.address)}
                                    </p>
                                  </div>
                                </div>
                              </li>

                              <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex gap-1 flex-1">
                                    <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                      Nationality:
                                    </p>
                                    <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                      {client.nationality}
                                    </p>
                                  </div>
                                </div>
                              </li>

                              <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex gap-1 flex-1">
                                    <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                      Next Of Kin:
                                    </p>
                                    <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                      {client.nextOfKin}
                                    </p>
                                  </div>
                                </div>
                              </li>

                              <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex gap-1 flex-1">
                                    <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                      Next Of Kin Phone:
                                    </p>
                                    <p className="capitalize  text-sm text-gray-500 dark:text-gray-400">
                                      {client.nextOfKinPhone}
                                    </p>
                                  </div>
                                </div>
                              </li>

                              <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                  <div className="min-w-0 flex gap-1">
                                    <p className=" text-sm font-medium text-gray-900 dark:text-white">
                                      Type Of Identification:
                                    </p>
                                    <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
                                      {client.typeOfIdentification}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="flex flex-col justify-center items-center pb-1">
                            <h5 className="font-bold my-4">Identification</h5>
                            {client.identification ? (
                              <div className="relative">
                                <img
                                  className="object-cover w-full md:w-1/2 lg:w-1/3 rounded-xl"
                                  src={client.identification}
                                  alt={`${client.firstName} ${client.lastName}`}
                                />
                                <Tooltip content="Change Client ID">
                                  <HiPencil
                                    size={16}
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: 10,
                                      right: 10,
                                    }}
                                    onClick={() => {
                                      setOpenModalImage(true);
                                      setImageOrId("identification");
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center">
                                <h3>No identification added yet.</h3>
                                <button
                                  className="mt-4 px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
                                  onClick={() => {
                                    setOpenModalImage(true);
                                    setImageOrId("identification");
                                  }}
                                >
                                  Upload Client ID
                                </button>
                              </div>
                            )}
                          </div>
                        </Card>
                      </Tabs.Item>

                      <Tabs.Item title="Subscriptions">
                        <div>
                          <div className="divide-y divide-gray-200 dark:divide-gray-700">
                            {subs?.length > 0 ? (
                              subs?.map((a, b) => {
                                return (
                                  <>
                                    <div
                                      key={b}
                                      className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-3 sm:p-4 bg-slate-100 mb-3 rounded-md"
                                    >
                                      <div className="flex flex-col">
                                        <DetailRow
                                          label="Property"
                                          value={a.propertyId.title}
                                        />
                                        <DetailRow
                                          label="Address"
                                          value={a.propertyId.address}
                                        />
                                        <DetailRow
                                          label="Payment Option"
                                          value={a.paymentOption}
                                        />
                                        <DetailRow
                                          label="Request Date"
                                          value={formatDate(a.createdAt)}
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <DetailRow
                                          label="Property Type"
                                          value={a.propertyId.propertyType}
                                        />
                                        <DetailRow
                                          label="Progress"
                                          value={`${a.progress}%`}
                                        />
                                        <DetailRow
                                          label="Approved"
                                          value={a.approved ? "Yes" : "No"}
                                        />
                                        <DetailRow
                                          label="Status"
                                          value={a.status}
                                        />
                                        <Actions
                                          a={a}
                                          handleAction={respondToSub}
                                          handleDelete={deleteSub}
                                        />
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <h3 className="text-center my-5">
                                Client has not subscribed to a property yet.
                              </h3>
                            )}
                          </div>
                        </div>
                      </Tabs.Item>
                    </Tabs.Group>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center h-[100%]">
                  <Spinner
                    style={{ color: "#0078e7" }}
                    aria-label="info spinner example"
                    size="xl"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      {/* modal for subscription */}
      <Modal show={openModal} onClose={() => handleCloseModal()}>
        <Modal.Header>Subscribe to Property</Modal.Header>
        <div className="flex justify-center">
          <small
            style={{
              color: errorColor,
              textAlign: "center",
            }}
          >
            {error}
          </small>
        </div>
        <Modal.Body>
          <form>
            <div className="grid grid-cols-1 gap-4">
              {/* Property ID */}
              <Select
                name="propertyId"
                placeholder="Select Property"
                value={subscription.propertyId}
                onChange={handleInputChange}
              >
                <option value="">Select Property</option>
                {properties.length > 0 &&
                  properties.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.title}-{e.propertyType}-{e.address}
                      </option>
                    );
                  })}
              </Select>

              {/* Payment Option */}
              <Select
                name="paymentOption"
                placeholder="Payment Option"
                value={subscription.paymentOption}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Payment Option</option>
                <option value="one-off">One-Off</option>
                <option value="installment">Installment</option>
              </Select>

              {/* PMB */}
              <TextInput
                name="pmb"
                placeholder="Primary Mortgage Bank"
                value={subscription.pmb}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading3} onClick={() => subscribeToProperty()}>
            {loading3 ? "Loading..." : "Submit"}
          </Button>
          <Button color="gray" onClick={() => handleCloseModal()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for client image */}
      <Modal show={openModalImage} onClose={() => handleCloseModalImage()}>
        <Modal.Header>
          {imageOrId === "image"
            ? "Edit Client Image"
            : "Change Client Identification"}
        </Modal.Header>
        <div className="flex justify-center">
          <small
            style={{
              color: errorColor,
              textAlign: "center",
            }}
          >
            {error}
          </small>
        </div>
        <Modal.Body>
          <form>
            <div className="grid grid-cols-1 gap-4">
              {/* File Input */}
              <div>
                <label
                  htmlFor="fileUpload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload File
                </label>
                <input
                  type="file"
                  id="fileUpload"
                  name="files"
                  accept=".jpg,.png,"
                  onChange={(e) => handleFileChange(e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <small className="text-gray-500">
                  Accepted formats: .jpg, .png
                </small>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading3} onClick={() => editImage()}>
            {loading3 ? "Loading..." : "Submit"}
          </Button>
          <Button color="gray" onClick={() => handleCloseModalImage()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for logout */}
      <Modal show={openModalOut} size="md" popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {msg}
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => logOut()}>
                {"OK"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for update */}
      <Modal show={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
        <Modal.Header>Update Subscription</Modal.Header>

        <Modal.Body>
          <form>
            <div className="grid grid-cols-1 gap-4">
              {/* Select Update Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Select Update Type
                </label>
                <select
                  value={updateType}
                  onChange={(e) => {
                    setUpdateType(e.target.value);
                    setUpdateValue(""); // Reset the input value when changing type
                  }}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">-- Select --</option>
                  <option value="progress">Progress</option>
                  <option value="status">Status</option>
                </select>
              </div>

              {/* Conditional Input for Progress */}
              {updateType === "progress" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Enter Progress (as a number)
                  </label>
                  <input
                    type="number"
                    value={updateValue}
                    onChange={(e) => setUpdateValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="e.g., 50"
                  />
                </div>
              )}

              {/* Conditional Input for Status */}
              {updateType === "status" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select Status
                  </label>
                  <select
                    value={updateValue}
                    onChange={(e) => setUpdateValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">-- Select Status --</option>
                    <option value="in-progress">In Progress</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={loadingUpdate || !updateType || !updateValue}
            onClick={async () => {
              if (!updateType || !updateValue) {
                alert("Please fill out all fields.");
                return;
              }

              const body =
                updateType === "progress"
                  ? { progress: Number(updateValue), approved: true }
                  : { status: updateValue, approved: true };

              setLoadingUpdate(true);
              await handleApiRequest(body, currentSubId);
              setLoadingUpdate(false);
              setOpenUpdateModal(false);
              setUpdateType("");
              setUpdateValue("");
            }}
          >
            {loadingUpdate ? "Updating..." : "Submit"}
          </Button>
          <Button
            color="gray"
            onClick={() => {
              setOpenUpdateModal(false);
              setUpdateType("");
              setUpdateValue("");
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SingleCustomer;

const DetailRow = ({ label, value }) => (
  <div className="flex items-center mb-2">
    <p className="text-sm font-medium text-gray-900 dark:text-white mr-2">
      {label}:
    </p>
    <p className="capitalize text-sm text-gray-500 dark:text-gray-400">
      {value}
    </p>
  </div>
);

const Actions = ({ a, handleAction, handleDelete }) => (
  <div className="flex justify-end gap-3">
    {!a.approved && (
      <button
        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        disabled={a.approved}
        onClick={() => handleDelete(a.id)}
      >
        Delete
      </button>
    )}
    {a.approved === false && a.status !== "cancelled" && (
      <button
        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        onClick={() => handleAction("cancel", a.id)}
      >
        Cancel
      </button>
    )}
    {a.approved === false && a.status !== "cancelled" && (
      <button
        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        onClick={() => handleAction("approve", a.id)}
      >
        Approve
      </button>
    )}

    {
      <button
        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => handleAction("update", a.id)}
      >
        Update
      </button>
    }
  </div>
);

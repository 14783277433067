import React, { useContext, useState } from "react";
import { Button, Card, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import img from "../assets/images/logo1.png";
import apiRequest from "../utils/apiRequest";
import { Store } from "../context/store";

// Page 1: Request Password Reset
export function RequestPasswordReset() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRequest = async () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    setLoading(true);
    try {
      const url = `${baseUrl}/request-password-reset`;
      const body = { email };
      const { message } = await apiRequest(url, "POST", body, null);
      setSuccess(message);
      setEmail("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <title>Forgot Password</title>
      <div className="login">
        <div className="container mx-auto">
          <div className="toast bg-[#fafafa]">
            <img src={img} className="h-14 " alt="Royalmines Logo" />
          </div>
          <div className="flex justify-center items-center h-screen">
            <Card className="w-3/4 md:w-1/2 lg:w-1/4 card">
              <h3 className="text-center font-extrabold text-2xl">
                Reset Password
              </h3>
              <p className="text-red-500 text-sm text-center">{error}</p>
              <p className="text-green-500 text-sm text-center">{success}</p>
              <div className="flex max-w-md flex-col gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email" value="Your email" />
                  </div>
                  <TextInput
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <Button
                  disabled={loading}
                  onClick={handleRequest}
                  className="bg-#6eb5ff"
                >
                  {loading ? "Sending..." : "Request Reset Link"}
                </Button>
              </div>
              <div className="flex justify-center">
                <Link to="/login">Back to Login</Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestPasswordReset; // Export the initial request component by default
